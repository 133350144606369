import AnalyticsProvider from '@root/core/src/contexts/analytics/analytics-provider';
import AnalyticsService from '@root/core/src/services/analytics-service';
import ErrorBoundary from '@root/core/src/components/error-boundary';
import PropTypes from '@root/vendor/prop-types';
import PublicRouter from '@root/rootbonus.com/src/components/public-router';
import SceneLoader from '@root/core/src/components/scene-loader';
import TrackVisitAttributionController from '@root/attribution/src/components/track-visit-attribution-controller';
import TrackingService from '@root/attribution/src/services/tracking-service';
import UrlManager from '@root/rootbonus.com/src/components/url-manager';
import useAnalytics from '@root/core/src/hooks/use-analytics';
import useSaveTouchAttributionEffect from '@root/attribution/src/hooks/use-save-touch-attribution-effect';
import { Suspense, useEffect } from '@root/vendor/react';

function App({ routerComponent: Router }) {
  useAnalytics('APP_ENTRY');
  useSaveTouchAttributionEffect();

  useEffect(() => {
    TrackingService.fireDistinctIdSetEvent(AnalyticsService.getDistinctId());
  }, []);

  return (
    <Router>
      <Suspense fallback={<SceneLoader hideHeader />}>
        <TrackVisitAttributionController />
        <UrlManager>
          <PublicRouter />
        </UrlManager>
      </Suspense>
    </Router>
  );
}

App.propTypes = {
  routerComponent: PropTypes.func.isRequired,
};

export default function AppEntry({ routerComponent }) {
  return (
    <ErrorBoundary>
      <AnalyticsProvider eventPrefix={'ROOT_BONUS'}>
        <App routerComponent={routerComponent} />
      </AnalyticsProvider>
    </ErrorBoundary>
  );
}

AppEntry.propTypes = {
  routerComponent: PropTypes.func.isRequired,
};
