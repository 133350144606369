import React, { lazy } from '@root/vendor/react';
import { Route, Switch } from '@root/core/src/components/route';
const Landing = lazy(() => import(/* webpackChunkName: "landing" */ '@root/rootbonus.com/src/scenes/landing'));
const SplashEntry = lazy(() => import(/* webpackChunkName: "openOnPhone" */ '@root/splash/src/splash-entry'));

export default function PublicRouter() {
  return (
    <Switch>
      <Route
        path={'/splash'}
        render={() => <SplashEntry passProps={{}} />}
      />
      <Route
        path={'(.*)'}
        render={() => <Landing />}
      />
    </Switch>
  );
}
