import PropTypes from '@root/vendor/prop-types';
import RootBonusContext from '@root/rootbonus.com/src/contexts/root-bonus/root-bonus-context';
import SceneLoader from '@root/core/src/components/scene-loader';
import getRootBonusContextConfiguration from '@root/rootbonus.com/src/api/root-bonus-context/root-bonus-context-configuration';
import useNetworkRequest from '@root/core/src/hooks/use-network-request';
import { useCallback, useMemo, useState } from '@root/vendor/react';

export default function UrlManager({ children }) {
  const originalUrl = window.location.href; // eslint-disable-line root/prevent-use-of-window-location
  const [rootBonusContext, setRootBonusContext] = useState({});
  const request = useMemo(() => getRootBonusContextConfiguration(originalUrl), [originalUrl]);

  const isLoading = useNetworkRequest(request, useCallback((result) => setRootBonusContext(result.data), []));

  if (isLoading) {
    return <SceneLoader hideHeader />;
  }

  return (
    <RootBonusContext.Provider value={rootBonusContext}>
      {children}
    </RootBonusContext.Provider>
  );
}

UrlManager.propTypes = {
  children: PropTypes.node.isRequired,
};
