import NetworkRequestConfiguration from '@root/core/src/networking/network-request-configuration';

export default function getRootBonusContextConfiguration(originalUrl) {
  return new NetworkRequestConfiguration({
    endpoint: 'root_bonus',
    method: NetworkRequestConfiguration.Methods.GET,
    queryParameters: {
      originalUrl,
    },
  });
}
