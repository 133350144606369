import PropTypes from '@root/vendor/prop-types';
import Responsive from '@root/core/src/utils/responsive';
import RootLogo from '@root/core/src/components/root-logo';
import RouteLink from '@root/core/src/components/route-link';
import ZIndex from '@root/core/src/utils/z-index';
import environment from '@root/core/src/utils/environment';
import { Colors, StyleSheet } from '@root/core/src/utils/styles';
import { Layout } from '@root/brand/src/utils/layout';

export default function Header({
  children,
  linkLocation = environment.websiteBaseUrl,
  progress = 0,
  showProgressBar = true,
  isLogoResponsive = false,
  isCssPositionFixed = false,
}) {
  const isRouteLink = linkLocation.search(/^https?:\/\//) === -1;

  const headerStyles = [
    styles.header,
    showProgressBar && styles.headerHideProgressBar,
    isCssPositionFixed && styles.headerFixed,
  ];

  return (
    <div css={headerStyles}>
      <div
        css={styles.container}
      >
        {!isRouteLink && (
          <a
            css={styles.logoCta}
            href={linkLocation}
            rel={'noopener noreferrer'}
            target={'_blank'}
          >
            <RootLogo isResponsive={isLogoResponsive} />
          </a>
        )}
        {isRouteLink && (
          <RouteLink
            css={styles.logoCta}
            to={linkLocation}
          >
            <RootLogo isResponsive={isLogoResponsive} />
          </RouteLink>
        )}
        {children}
      </div>
      {showProgressBar &&
      <div
        css={styles.progressBar}
      >
        <div
          css={[styles.progressBarFill, {
            width: `${progress}%`,
          }]}
          data-testid={'header-progress-bar-fill'}
        />
      </div>
      }
    </div>
  );
}

const styles = StyleSheet.create({
  header: {
    position: 'sticky',
    height: Layout.navbarHeight(),
    top: 0,
    left: 0,
    width: '100%',
    zIndex: ZIndex.HEADER,
    background: Colors.white(),
  },
  headerFixed: {
    position: 'fixed',
  },
  headerHideProgressBar: {
    height: Layout.navbarHeight(false),
  },
  container: {
    background: Colors.white(),
    height: Layout.navbarHeight(false),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 20,
    paddingRight: 20,
    ...Responsive.md({
      paddingLeft: 80,
      paddingRight: 80,
    }),
  },
  logoCta: {
    color: Colors.black(),
    transition: 'color 0.15s ease-out',
    ':hover': {
      color: Colors.rootOrange(),
    },
  },
  progressBar: {
    height: Layout.PROGRESS_BAR_HEIGHT,
    width: '100%',
    background: Colors.nearWhite(),
  },
  progressBarFill: {
    background: Colors.nearBlack(),
    height: '100%',
    maxWidth: '100%',
    transition: 'width 300ms ease-in',
  },
});

Header.propTypes = {
  children: PropTypes.node,
  isCssPositionFixed: PropTypes.bool,
  isLogoResponsive: PropTypes.bool,
  linkLocation: PropTypes.string,
  progress: PropTypes.number,
  showProgressBar: PropTypes.bool,
};
